<script>
import Multiselect from "vue-multiselect";
import axios from "axios";

let user = JSON.parse(localStorage.getItem("user"));
let token = user.token;

export default {
  props: ["reservations", "editProps"],
  components: { Multiselect },
  data() {
    return {
      hotel:
        this.reservations && this.reservations.hotel
          ? this.reservations.hotel
          : "",
      selectedRooms: [],
      selectedRoom: 0,
      hotelReservation: {
        hotel_id: "",
        room_id: "",
        room_count: "",
        baby_count: "",
        children_count: "",
        adult_count: "",
        confirme_number: "",
        buy_price: "",
        sell_price: "",
        buy_currency: "",
        sell_currency: "",
        first_date: "",
        last_date: "",
        board: "",
        cancelability: "",
      },
      boards: [
        { value: 1, label: "Room Only" },
        { value: 2, label: "Breakfast" },
        { value: 3, label: "Half Board" },
        { value: 4, label: "Full Board" },
      ],
      cancelabilitys: [
        { value: 1, label: "İptal Edilir" },
        { value: 2, label: "İptal Edilmez" },
      ],
    };
  },
  created() {
    this.$root.$on("minFirstDateUpdated", (minFirstDate) => {
      console.log(minFirstDate);
    });
  },
  methods: {
    handleSelect(selectedItem) {
      this.hotel = selectedItem;
      let hotelId = this.hotel.id;
      axios
        .get(process.env.VUE_APP_BASEURL + "/hotel/" + hotelId + "/rooms", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          this.editProps.rooms = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    addHotelToReservation() {
      if (this.editProps.hotels && this.selectedRoom) {
        const newReservation = {
          hotel: this.hotel,
          room: this.selectedRoom,
          room_count: this.hotelReservation.room_count,
          baby_count: this.hotelReservation.baby_count,
          children_count: this.hotelReservation.children_count,
          adult_count: this.hotelReservation.adult_count,
          confirme_number: this.hotelReservation.confirme_number,
          buy_price: this.hotelReservation.buy_price,
          sell_price: this.hotelReservation.sell_price,
          buy_currency: this.hotelReservation.buy_currency,
          sell_currency: this.hotelReservation.sell_currency,
          first_date: this.hotelReservation.first_date,
          last_date: this.hotelReservation.last_date,
          board: this.hotelReservation.board,
          cancelability: this.hotelReservation.cancelability,
          boards: this.boards,
          cancelabilitys: this.cancelabilitys,
        };
        this.reservations.push(newReservation);
        console.log(newReservation);
        this.clearReservationData();
        this.setMinFirstDate();
      }
    },
    clearReservationData() {
      this.hotel = "";
      this.selectedRoom = [];
      this.hotelReservation = {
        room_count: "",
        baby_count: "",
        children_count: "",
        adult_count: "",
        confirme_number: "",
        buy_price: "",
        sell_price: "",
        buy_currency: "",
        sell_currency: "",
        first_date: "",
        last_date: "",
        board: "",
        cancelability: "",
      };
    },
    setMinFirstDate() {
      const reservationFirstDates = this.reservations.map(
        (row) => new Date(row.first_date)
      );
      this.minFirstDate = this.$moment(
        new Date(Math.min(...reservationFirstDates))
      ).format("YYYY-MM-DD");
      this.$root.$emit("minFirstDateUpdated", this.minFirstDate);
    },

    isFormValid() {
      return (
        this.hotel &&
        this.selectedRoom &&
        this.hotelReservation.room_count &&
        this.hotelReservation.baby_count &&
        this.hotelReservation.children_count &&
        this.hotelReservation.adult_count &&
        this.hotelReservation.confirme_number &&
        this.hotelReservation.buy_price &&
        this.hotelReservation.sell_price &&
        this.hotelReservation.buy_currency &&
        this.hotelReservation.sell_currency &&
        this.hotelReservation.first_date &&
        this.hotelReservation.last_date &&
        this.hotelReservation.board &&
        this.hotelReservation.cancelability
      );
    },
    resetErrorMessages() {
      this.$refs.HotelErrorMessage.style.display = "none";
      this.$refs.RoomErrorMessage.style.display = "none";
      this.$refs.RoomCountErrorMessage.style.display = "none";
      this.$refs.BabyCountErrorMessage.style.display = "none";
      this.$refs.ChildrenCountErrorMessage.style.display = "none";
      this.$refs.AdultCountErrorMessage.style.display = "none";
      this.$refs.ConfirmeNumberErrorMessage.style.display = "none";
      this.$refs.BuyPriceErrorMessage.style.display = "none";
      this.$refs.SellPriceErrorMessage.style.display = "none";
      this.$refs.BuyCurrencyErrorMessage.style.display = "none";
      this.$refs.SellCurrencyErrorMessage.style.display = "none";
      this.$refs.FirstDateErrorMessage.style.display = "none";
      this.$refs.LastDateErrorMessage.style.display = "none";
      this.$refs.BoardErrorMessage.style.display = "none";
      this.$refs.CancelabilityErrorMessage.style.display = "none";

      if (!this.hotel) {
        this.$refs.HotelErrorMessage.style.display = "block";
      }
      if (!this.selectedRoom) {
        this.$refs.RoomErrorMessage.style.display = "block";
      }
      if (!this.hotelReservation.room_count) {
        this.$refs.RoomCountErrorMessage.style.display = "block";
      }
      if (!this.hotelReservation.baby_count) {
        this.$refs.BabyCountErrorMessage.style.display = "block";
      }
      if (!this.hotelReservation.children_count) {
        this.$refs.ChildrenCountErrorMessage.style.display = "block";
      }
      if (!this.hotelReservation.adult_count) {
        this.$refs.AdultCountErrorMessage.style.display = "block";
      }
      if (!this.hotelReservation.confirme_number) {
        this.$refs.ConfirmeNumberErrorMessage.style.display = "block";
      }
      if (!this.hotelReservation.buy_price) {
        this.$refs.BuyPriceErrorMessage.style.display = "block";
      }
      if (!this.hotelReservation.sell_price) {
        this.$refs.SellPriceErrorMessage.style.display = "block";
      }
      if (!this.hotelReservation.buy_currency) {
        this.$refs.BuyCurrencyErrorMessage.style.display = "block";
      }
      if (!this.hotelReservation.sell_currency) {
        this.$refs.SellCurrencyErrorMessage.style.display = "block";
      }
      if (!this.hotelReservation.first_date) {
        this.$refs.FirstDateErrorMessage.style.display = "block";
      }
      if (!this.hotelReservation.last_date) {
        this.$refs.LastDateErrorMessage.style.display = "block";
      }
      if (!this.hotelReservation.board) {
        this.$refs.BoardErrorMessage.style.display = "block";
      }
      if (!this.hotelReservation.cancelability) {
        this.$refs.CancelabilityErrorMessage.style.display = "block";
      }
    },

    submitForm() {
      this.resetErrorMessages();

      if (!this.isFormValid()) {
        alert("Lütfen tüm alanları doldurun.");
        return;
      }

      this.addHotelToReservation();
    },
    today() {
      return new Date();
    },
    disabledDates(date) {
      // Bugünden önceki tarihleri devre dışı bırak
      return date < this.today();
    },
    formatDate(date) {
      // Tarihi "YYYY-MM-DD" biçimine dönüştür
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
  },
};
</script>

<template>
  <div class="row">
    <div class="col-12 col-md-12">
      <div class="form-group mb-3">
        <label for="customer_name">Otel</label>
        <multiselect
          v-model="hotel"
          :options="this.editProps.hotels"
          label="brand_name"
          @select="handleSelect"
        >
        </multiselect>
        <label
          class="error-input"
          ref="HotelErrorMessage"
          style="display: none"
        >
          Bu alan boş bırakılamaz.
        </label>
      </div>
    </div>
    <div class="col-12 col-md-3">
      <div class="form-group mb-3">
        <label for="customer_name">Oda Tipi</label>
        <multiselect
          v-model="selectedRoom"
          :disabled="this.editProps.rooms.length === 0"
          :options="this.editProps.rooms"
          label="room_name"
        ></multiselect>
        <label class="error-input" ref="RoomErrorMessage" style="display: none">
          Bu alan boş bırakılamaz.
        </label>
      </div>
    </div>
    <div class="col-12 col-md-3">
      <div class="form-group mb-3">
        <label for="customer_name">Oda Sayısı</label>
        <input
          v-model="hotelReservation.room_count"
          type="number"
          class="form-control"
          id="room_count"
        />
        <label
          class="error-input"
          ref="RoomCountErrorMessage"
          style="display: none"
        >
          Bu alan boş bırakılamaz.
        </label>
      </div>
    </div>
    <div class="col-12 col-md-3">
      <div class="form-group mb-3">
        <label for="customer_name">Giriş Tarihi</label>
        <input
          class="form-control"
          type="date"
          v-model="hotelReservation.first_date"
          :first-day-of-week="1"
          lang="tr"
          :min="formatDate(today())"
        />
        <label
          class="error-input"
          ref="FirstDateErrorMessage"
          style="display: none"
        >
          Bu alan boş bırakılamaz.
        </label>
      </div>
    </div>
    <div class="col-12 col-md-3">
      <div class="form-group mb-3">
        <label for="customer_name">Çıkış Tarihi</label>
        <input
          class="form-control"
          type="date"
          v-model="hotelReservation.last_date"
          :first-day-of-week="1"
          :min="hotelReservation.first_date"
          lang="tr"
        />
        <label
          class="error-input"
          ref="LastDateErrorMessage"
          style="display: none"
        >
          Bu alan boş bırakılamaz.
        </label>
      </div>
    </div>
    <div class="col-12 col-md-3">
      <div class="form-group mb-3">
        <label for="customer_name">Alış Fiyatı</label>
        <input
          v-model="hotelReservation.buy_price"
          type="text"
          class="form-control"
          id="buy_price"
        />
        <label
          class="error-input"
          ref="BuyPriceErrorMessage"
          style="display: none"
        >
          Bu alan boş bırakılamaz.
        </label>
      </div>
    </div>
    <div class="col-12 col-md-3">
      <div class="form-group mb-3">
        <label for="kur">Alış Kur</label>
        <multiselect
          v-model="hotelReservation.buy_currency"
          :options="this.editProps.currencies"
          label="name"
        >
        </multiselect>
        <label
          class="error-input"
          ref="BuyCurrencyErrorMessage"
          style="display: none"
        >
          Bu alan boş bırakılamaz.
        </label>
      </div>
    </div>
    <div class="col-12 col-md-3">
      <div class="form-group mb-3">
        <label for="customer_name">Satış Fiyatı</label>
        <input
          v-model="hotelReservation.sell_price"
          type="double"
          class="form-control"
          id="sell_price"
        />
        <label
          class="error-input"
          ref="SellPriceErrorMessage"
          style="display: none"
        >
          Bu alan boş bırakılamaz.
        </label>
      </div>
    </div>
    <div class="col-12 col-md-3">
      <div class="form-group mb-3">
        <label for="kur">Satış Kur</label>
        <multiselect
          v-model="hotelReservation.sell_currency"
          :options="this.editProps.currencies"
          label="name"
          :hover="false"
        ></multiselect>
        <label
          class="error-input"
          ref="SellCurrencyErrorMessage"
          style="display: none"
        >
          Bu alan boş bırakılamaz.
        </label>
      </div>
    </div>
    <div class="col-12 col-md-3">
      <div class="form-group mb-3">
        <label for="boards">Board</label>
        <multiselect
          v-model="hotelReservation.board"
          :options="boards"
          label="label"
        ></multiselect>
        <label
          class="error-input"
          ref="BoardErrorMessage"
          style="display: none"
        >
          Bu alan boş bırakılamaz.
        </label>
      </div>
    </div>
    <div class="col-12 col-md-3">
      <div class="form-group mb-3">
        <label for="cancelability">İptal Edilebilirlik?</label>
        <multiselect
          v-model="hotelReservation.cancelability"
          :options="cancelabilitys"
          label="label"
        >
        </multiselect>
        <label
          class="error-input"
          ref="CancelabilityErrorMessage"
          style="display: none"
        >
          Bu alan boş bırakılamaz.
        </label>
      </div>
    </div>
    <div class="col-12 col-md-3">
      <div class="form-group mb-3">
        <label for="customer_name">Bebek</label>
        <input
          v-model="hotelReservation.baby_count"
          type="number"
          class="form-control"
          id="baby_count"
        />
        <label
          class="error-input"
          ref="BabyCountErrorMessage"
          style="display: none"
        >
          Bu alan boş bırakılamaz.
        </label>
      </div>
    </div>
    <div class="col-12 col-md-3">
      <div class="form-group mb-3">
        <label for="customer_name">Çocuk</label>
        <input
          v-model="hotelReservation.children_count"
          type="number"
          class="form-control"
          id="children_count"
        />
        <label
          class="error-input"
          ref="ChildrenCountErrorMessage"
          style="display: none"
        >
          Bu alan boş bırakılamaz.
        </label>
      </div>
    </div>
    <div class="col-12 col-md-3">
      <div class="form-group mb-3">
        <label for="customer_name">Yetişkin</label>
        <input
          v-model="hotelReservation.adult_count"
          type="number"
          class="form-control"
          id="adult_count"
        />
        <label
          class="error-input"
          ref="AdultCountErrorMessage"
          style="display: none"
        >
          Bu alan boş bırakılamaz.
        </label>
      </div>
    </div>
    <div class="col-12 col-md-3">
      <div class="form-group mb-3">
        <label for="customer_name">Confirme</label>
        <input
          v-model="hotelReservation.confirme_number"
          type="text"
          class="form-control"
          id="confirme_number"
        />
        <label
          class="error-input"
          ref="ConfirmeNumberErrorMessage"
          style="display: none"
        >
          Bu alan boş bırakılamaz.
        </label>
      </div>
    </div>
    <div class="col-12 col-md-12">
      <button class="float-right btn btn-success w-100" @click="submitForm">
        Otel Ekle
      </button>
    </div>
  </div>
</template>
<style>
/* Hata alanlarını vurgulamak için kırmızı sınır ekleyin */
.error-input {
  color: red;
}
</style>
