FlyComponent.vue

<script>
import Multiselect from "vue-multiselect";
import format from "date-fns/format";
import { flyPacket } from "@/components/enums.js";

// import axios from "axios";

// let user = JSON.parse(localStorage.getItem("user"));
// let token = user.token;

export default {
  props: ["reservations", "flyRoutes", "editProps"],
  components: { Multiselect },
  data() {
    return {
      status: "not_accepted",
      flyPacket,
      flyReservation: {
        airline_name: "",
        flight_number: "",
        flight_pnr: "",
        flight_from: "",
        flight_to: "",
        flight_from_date: "",
        flight_to_date: "",
        flight_ticket_number: "",
        flight_ticket_class: "",
        flight_baggage: 15,
        reservation_number: "",
        packet: "",
        preferences: "",
        buy_price: "",
        sell_price: "",
        buy_currency: "",
        sell_currency: "",
        customer: "",
      },
      returnFlyReservation: {
        flight_from: "",
        flight_to: "",
        flight_from_date: "",
        flight_to_date: "",
        flight_ticket_number: "",
        flight_ticket_class: "",
        flight_baggage: "",
        packet: "",
        preferences: "",
        buy_price: "",
        sell_price: "",
        buy_currency: "",
        sell_currency: "",
      },
    };
  },
  methods: {
    addFlyToReservation() {
      const newReservation = {
        airline_name: this.flyReservation.airline_name,
        flight_number: this.flyReservation.flight_number,
        flight_pnr: this.flyReservation.flight_pnr,
        flight_from: this.flyReservation.flight_from,
        flight_to: this.flyReservation.flight_to,
        flight_from_date: this.flyReservation.flight_from_date,
        flight_to_date: this.flyReservation.flight_to_date,
        flight_ticket_number: this.flyReservation.flight_ticket_number,
        flight_ticket_class: this.flyReservation.flight_ticket_class,
        flight_baggage: this.flyReservation.flight_baggage,
        packet: this.flyReservation.packet,
        preferences: this.flyReservation.preferences,
        reservation_number: this.flyReservation.reservation_number,
        buy_price: this.flyReservation.buy_price,
        sell_price: this.flyReservation.sell_price,
        buy_currency: this.flyReservation.buy_currency,
        sell_currency: this.flyReservation.sell_currency,
        customer: this.flyReservation.customer,
      };
      this.flyRoutes.push(newReservation);
      if (this.status === "accepted" && this.isReturnFlightValid()) {
        const newReturnReservation = {
          flight_from: this.returnFlyReservation.flight_from,
          flight_to: this.returnFlyReservation.flight_to,
          flight_from_date: this.returnFlyReservation.flight_from_date,
          flight_to_date: this.returnFlyReservation.flight_to_date,
          flight_ticket_number: this.returnFlyReservation.flight_ticket_number,
          flight_ticket_class: this.returnFlyReservation.flight_ticket_class,
          flight_baggage: this.returnFlyReservation.flight_baggage,
          packet: this.returnFlyReservation.packet,
          preferences: this.returnFlyReservation.preferences,
          buy_price: this.returnFlyReservation.buy_price,
          sell_price: this.returnFlyReservation.sell_price,
          buy_currency: this.returnFlyReservation.buy_currency,
          sell_currency: this.returnFlyReservation.sell_currency,
        };
        this.flyRoutes.push(newReturnReservation);
      }
      console.log(newReservation);
      this.clearReservationData();
    },
    isReturnFlightValid() {
      const {
        flight_number,
        flight_from,
        flight_to,
        flight_from_date,
        flight_to_date,
        flight_ticket_class,
        packet,
        flight_baggage,
        buy_price,
        sell_price,
        buy_currency,
        sell_currency,
      } = this.returnFlyReservation;
      return (
        flight_number &&
        flight_from &&
        flight_to &&
        flight_from_date &&
        flight_to_date &&
        flight_ticket_class &&
        packet &&
        flight_baggage &&
        buy_price &&
        sell_price &&
        buy_currency &&
        sell_currency
      );
    },
    clearReservationData() {
      this.flyReservation = {
        airline_name: "",
        flight_number: "",
        flight_pnr: "",
        flight_from: "",
        flight_to: "",
        flight_from_date: "",
        flight_to_date: "",
        flight_ticket_number: "",
        flight_ticket_class: "",
        flight_baggage: "",
        reservation_number: "",
        packet: "",
        preferences: "",
        buy_price: "",
        sell_price: "",
        buy_currency: "",
        sell_currency: "",
        customer: "",
      };
      this.returnFlyReservation = {
        flight_from: "",
        flight_to: "",
        flight_from_date: "",
        flight_to_date: "",
        flight_ticket_number: "",
        flight_ticket_class: "",
        flight_baggage: "",
        packet: "",
        preferences: "",
        buy_price: "",
        sell_price: "",
        buy_currency: "",
        sell_currency: "",
      };
    },
    formatDate(date) {
      return format(date, "dd/MM/yyyy");
    },
    formatDateData(date) {
      return format(date, "yyyy-MM-dd");
    },

    submitForm() {
      this.addFlyToReservation();
    },
  },
};
</script>

<template>
  <div class="row">
    <div class="col-12 col-md-12">
      <b-form-checkbox
        id="checkbox-1"
        v-model="status"
        name="checkbox-1"
        value="accepted"
        unchecked-value="not_accepted"
      >
        Gidiş - Dönüş
      </b-form-checkbox>
      <hr />
    </div>
    <div class="col-12 col-md-3">
      <div class="form-group mb-3">
        <label for="airline_name">Havayolu Şirketi</label>
        <input
          v-model="flyReservation.airline_name"
          type="text"
          class="form-control"
          id="room_count"
        />
        <label
          class="error-input"
          ref="AirlineNameErrorMessage"
          style="display: none"
        >
          Bu alan boş bırakılamaz.
        </label>
      </div>
    </div>
    <div class="col-12 col-md-3">
      <div class="form-group mb-3">
        <label for="customer_name">Uçuş Kodu</label>
        <input
          v-model="flyReservation.flight_number"
          type="text"
          class="form-control"
          id="room_count"
        />
        <label
          class="error-input"
          ref="FlightNumberErrorMessage"
          style="display: none"
        >
          Bu alan boş bırakılamaz.
        </label>
      </div>
    </div>
    <div class="col-12 col-md-3">
      <div class="form-group mb-3">
        <label for="reservation_number">Rezervasyon Kodu</label>
        <input
          v-model="flyReservation.reservation_number"
          type="text"
          class="form-control"
          id="reservation_number"
        />
        <label
          class="error-input"
          ref="FlightReservationNumberMessage"
          style="display: none"
        >
          Bu alan boş bırakılamaz.
        </label>
      </div>
    </div>
    <div class="col-12 col-md-3">
      <div class="form-group mb-3">
        <label for="customer_name">PNR Kodu</label>
        <input
          v-model="flyReservation.flight_pnr"
          type="text"
          class="form-control"
          id="room_count"
        />
        <label
          class="error-input"
          ref="FlightPnrErrorMessage"
          style="display: none"
        >
          Bu alan boş bırakılamaz.
        </label>
      </div>
    </div>
    <div class="col-12 col-md-3">
      <div class="form-group mb-3">
        <label for="customer_name">Kalkış</label>
        <multiselect
          v-model="flyReservation.flight_from"
          :options="editProps.airports"
          label="name"
        >
        </multiselect>
        <label
          class="error-input"
          ref="FlightFromErrorMessage"
          style="display: none"
        >
          Bu alan boş bırakılamaz.
        </label>
      </div>
    </div>
    <div class="col-12 col-md-3">
      <div class="form-group mb-3">
        <label for="customer_name">Varış</label>
        <multiselect
          v-model="flyReservation.flight_to"
          :options="editProps.airports"
          label="name"
        >
        </multiselect>
        <label
          class="error-input"
          ref="FlightToErrorMessage"
          style="display: none"
        >
          Bu alan boş bırakılamaz.
        </label>
      </div>
    </div>
    <div class="col-12 col-md-3">
      <div class="form-group mb-3">
        <label for="customer_name">Kalkış Zamanı</label>
        <input
          type="datetime-local"
          v-model="flyReservation.flight_from_date"
          :first-day-of-week="1"
          lang="tr"
          class="form-control"
        />
        <label
          class="error-input"
          ref="FlightFromDateErrorMessage"
          style="display: none"
        >
          Bu alan boş bırakılamaz.
        </label>
      </div>
    </div>
    <div class="col-12 col-md-3">
      <div class="form-group mb-3">
        <label for="customer_name">Varış Zamanı</label>
        <input
          type="datetime-local"
          v-model="flyReservation.flight_to_date"
          :first-day-of-week="1"
          lang="tr"
          class="form-control"
        />
        <label
          class="error-input"
          ref="FlightToDateErrorMessage"
          style="display: none"
        >
          Bu alan boş bırakılamaz.
        </label>
      </div>
    </div>
    <div class="col-12 col-md-3">
      <div class="form-group mb-3">
        <label for="customer_name">Bilet Numarası</label>
        <input
          v-model="flyReservation.flight_ticket_number"
          type="text"
          class="form-control"
          id="room_count"
        />
        <label
          class="error-input"
          ref="FlightTicketNumberErrorMessage"
          style="display: none"
        >
          Bu alan boş bırakılamaz.
        </label>
      </div>
    </div>
    <div class="col-12 col-md-3">
      <div class="form-group mb-3">
        <label for="customer_name">Bilet Sınıfı</label>
        <multiselect
          v-model="flyReservation.flight_ticket_class"
          :options="flyPacket"
          label="text"
        >
        </multiselect>
        <label
          class="error-input"
          ref="FlightTicketClassErrorMessage"
          style="display: none"
        >
          Bu alan boş bırakılamaz.
        </label>
      </div>
    </div>
    <div class="col-12 col-md-3">
      <div class="form-group mb-3">
        <label for="packet">Paket</label>
        <input
          v-model="flyReservation.packet"
          type="text"
          class="form-control"
          id="packet"
        />
        <label
          class="error-input"
          ref="FlightPacketErrorMessage"
          style="display: none"
        >
          Bu alan boş bırakılamaz.
        </label>
      </div>
    </div>
    <div class="col-12 col-md-3">
      <div class="form-group mb-3">
        <label for="customer_name">Bagaj Hakkı (kg)</label>
        <input
          v-model="flyReservation.flight_baggage"
          type="number"
          class="form-control"
          id="room_count"
        />
        <label
          class="error-input"
          ref="FlightBaggageErrorMessage"
          style="display: none"
        >
          Bu alan boş bırakılamaz.
        </label>
      </div>
    </div>
    <div class="col-12 col-md-3">
      <div class="form-group mb-3">
        <label for="customer_name">Alış Fiyatı</label>
        <input
          v-model="flyReservation.buy_price"
          type="double"
          class="form-control"
          id="sell_price"
        />
        <label
          class="error-input"
          ref="BuyPriceErrorMessage"
          style="display: none"
        >
          Bu alan boş bırakılamaz.
        </label>
      </div>
    </div>
    <div class="col-12 col-md-3">
      <div class="form-group mb-3">
        <label for="kur">Alış Kur</label>
        <multiselect
          v-model="flyReservation.buy_currency"
          :options="this.editProps.currencies"
          label="name"
        >
        </multiselect>
        <label
          class="error-input"
          ref="BuyCurrencyErrorMessage"
          style="display: none"
        >
          Bu alan boş bırakılamaz.
        </label>
      </div>
    </div>
    <div class="col-12 col-md-3">
      <div class="form-group mb-3">
        <label for="customer_name">Satış Fiyatı</label>
        <input
          v-model="flyReservation.sell_price"
          type="double"
          class="form-control"
          id="sell_price"
        />
        <label
          class="error-input"
          ref="SellPriceErrorMessage"
          style="display: none"
        >
          Bu alan boş bırakılamaz.
        </label>
      </div>
    </div>
    <div class="col-12 col-md-3">
      <div class="form-group mb-3">
        <label for="kur">Satış Kur</label>
        <multiselect
          v-model="flyReservation.sell_currency"
          :options="this.editProps.currencies"
          label="name"
          :hover="false"
        ></multiselect>
        <label
          class="error-input"
          ref="SellCurrencyErrorMessage"
          style="display: none"
        >
          Bu alan boş bırakılamaz.
        </label>
      </div>
    </div>
    <div class="col-12 col-md-3">
      <div class="form-group mb-3">
        <label for="boards">Yolcu</label>
        <multiselect
          v-model="flyReservation.customer"
          :options="this.editProps.customers"
          label="customer_name"
        ></multiselect>
        <label
          class="error-input"
          ref="CustomerErrorMessage"
          style="display: none"
        >
          Bu alan boş bırakılamaz.
        </label>
      </div>
    </div>
    <div class="col-12 col-md-9">
      <div class="form-group mb-3">
        <label for="preferences">Tercihler</label> <br />
        <textarea
          v-model="flyReservation.preferences"
          class="form-control"
        ></textarea>
        <label
          class="error-input"
          ref="PreferencesErrorMessage"
          style="display: none"
        >
          Bu alan boş bırakılamaz.
        </label>
      </div>
    </div>
    <div v-if="status === 'accepted'" class="col-12 col-md-12">
      <h5>Dönüş Uçuşu</h5>
      <hr />
      <div class="row">
        <div class="col-12 col-md-3">
          <div class="form-group mb-3">
            <label for="customer_name">Uçuş Kodu</label>
            <input
              v-model="returnFlyReservation.flight_number"
              type="text"
              class="form-control"
              id="return_room_count"
            />
            <!-- <label
              class="error-input"
              ref="FlightNumberErrorMessage"
              style="display: none"
            >
              Bu alan boş bırakılamaz.
            </label> -->
          </div>
        </div>
        <div class="col-12 col-md-3">
          <div class="form-group mb-3">
            <label for="customer_name">Kalkış</label>
            <multiselect
              v-model="returnFlyReservation.flight_from"
              :options="editProps.airports"
              label="name"
            >
            </multiselect>
            <!-- <label
              class="error-input"
              ref="FlightFromErrorMessage"
              style="display: none"
            >
              Bu alan boş bırakılamaz.
            </label> -->
          </div>
        </div>
        <div class="col-12 col-md-3">
          <div class="form-group mb-3">
            <label for="customer_name">Varış</label>
            <multiselect
              v-model="returnFlyReservation.flight_to"
              :options="editProps.airports"
              label="name"
            >
            </multiselect>
            <!-- <label
              class="error-input"
              ref="FlightToErrorMessage"
              style="display: none"
            >
              Bu alan boş bırakılamaz.
            </label> -->
          </div>
        </div>
        <div class="col-12 col-md-3">
          <div class="form-group mb-3">
            <label for="customer_name">Kalkış Zamanı</label>
            <input
              type="datetime-local"
              v-model="returnFlyReservation.flight_from_date"
              :first-day-of-week="1"
              lang="tr"
              class="form-control"
            />
            <!-- <label
              class="error-input"
              ref="FlightFromDateErrorMessage"
              style="display: none"
            >
              Bu alan boş bırakılamaz.
            </label> -->
          </div>
        </div>
        <div class="col-12 col-md-3">
          <div class="form-group mb-3">
            <label for="customer_name">Varış Zamanı</label>
            <input
              type="datetime-local"
              v-model="returnFlyReservation.flight_to_date"
              :first-day-of-week="1"
              lang="tr"
              class="form-control"
            />
            <!-- <label
              class="error-input"
              ref="FlightToDateErrorMessage"
              style="display: none"
            >
              Bu alan boş bırakılamaz.
            </label> -->
          </div>
        </div>
        <div class="col-12 col-md-3">
          <div class="form-group mb-3">
            <label for="customer_name">Bilet Sınıfı</label>
            <multiselect
              v-model="returnFlyReservation.flight_ticket_class"
              :options="flyPacket"
              label="text"
            >
            </multiselect>
            <!-- <label
              class="error-input"
              ref="FlightTicketClassErrorMessage"
              style="display: none"
            >
              Bu alan boş bırakılamaz.
            </label> -->
          </div>
        </div>
        <div class="col-12 col-md-3">
          <div class="form-group mb-3">
            <label for="packet">Paket</label>
            <input
              v-model="returnFlyReservation.packet"
              type="text"
              class="form-control"
              id="packet"
            />
            <!-- <label
              class="error-input"
              ref="FlightPacketErrorMessage"
              style="display: none"
            >
              Bu alan boş bırakılamaz.
            </label> -->
          </div>
        </div>
        <div class="col-12 col-md-3">
          <div class="form-group mb-3">
            <label for="customer_name">Bagaj Hakkı (kg)</label>
            <input
              v-model="returnFlyReservation.flight_baggage"
              type="number"
              class="form-control"
              id="room_count"
            />
            <!-- <label
              class="error-input"
              ref="FlightBaggageErrorMessage"
              style="display: none"
            >
              Bu alan boş bırakılamaz.
            </label> -->
          </div>
        </div>
        <div class="col-12 col-md-3">
          <div class="form-group mb-3">
            <label for="customer_name">Alış Fiyatı</label>
            <input
              v-model="returnFlyReservation.buy_price"
              type="double"
              class="form-control"
              id="sell_price"
            />
            <!-- <label
              class="error-input"
              ref="BuyPriceErrorMessage"
              style="display: none"
            >
              Bu alan boş bırakılamaz.
            </label> -->
          </div>
        </div>
        <div class="col-12 col-md-3">
          <div class="form-group mb-3">
            <label for="kur">Alış Kur</label>
            <multiselect
              v-model="returnFlyReservation.buy_currency"
              :options="this.editProps.currencies"
              label="name"
            >
            </multiselect>
            <!-- <label
              class="error-input"
              ref="BuyCurrencyErrorMessage"
              style="display: none"
            >
              Bu alan boş bırakılamaz.
            </label> -->
          </div>
        </div>
        <div class="col-12 col-md-3">
          <div class="form-group mb-3">
            <label for="customer_name">Satış Fiyatı</label>
            <input
              v-model="returnFlyReservation.sell_price"
              type="double"
              class="form-control"
              id="sell_price"
            />
            <!-- <label
              class="error-input"
              ref="SellPriceErrorMessage"
              style="display: none"
            >
              Bu alan boş bırakılamaz.
            </label> -->
          </div>
        </div>
        <div class="col-12 col-md-3">
          <div class="form-group mb-3">
            <label for="kur">Satış Kur</label>
            <multiselect
              v-model="returnFlyReservation.sell_currency"
              :options="this.editProps.currencies"
              label="name"
              :hover="false"
            ></multiselect>
            <!-- <label
              class="error-input"
              ref="SellCurrencyErrorMessage"
              style="display: none"
            >
              Bu alan boş bırakılamaz.
            </label> -->
          </div>
        </div>
        <div class="col-12 col-md-12">
          <div class="form-group mb-3">
            <label for="preferences">Tercihler</label> <br />
            <textarea
              v-model="returnFlyReservation.preferences"
              class="form-control"
            ></textarea>
            <!-- <label
              class="error-input"
              ref="PreferencesErrorMessage"
              style="display: none"
            >
              Bu alan boş bırakılamaz.
            </label> -->
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-12">
      <button class="float-right btn btn-success w-100" @click="submitForm">
        Uçuş Ekle
      </button>
    </div>
  </div>
</template>
<style>
/* Hata alanlarını vurgulamak için kırmızı sınır ekleyin */
.error-input {
  color: red;
}
</style>
