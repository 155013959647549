<script>
import Multiselect from "vue-multiselect";
import axios from "axios";
import format from "date-fns/format";
import { statusEnum } from "@/components/enums.js";

let user = JSON.parse(localStorage.getItem("user"));
let token = user.token;

export default {
  props: ["selectedValues"],
  components: {
    Multiselect,
  },
  data() {
    return {
      statusEnum,
      customer: "",
      customers: [],
      agency: "",
      agencies: [],
      values: {
        customer: "",
        agency: "",
        reservation_type: "",
        status: 0,
        note: "",
      },
    };
  },

  created() {
    this.getAllAgency(), this.getAllCustomer();
  },

  methods: {
    updateSelectedValues() {
      this.$emit("update:selectedValues", {
        ...this.selectedValues,
        customer: this.values.customer,
        agency: this.values.agency,
        status: this.values.status,
        note: this.values.note,
      });
    },
    getAllCustomer() {
      const api_url = process.env.VUE_APP_BASEURL + "/customer/getAll";
      axios
        .get(api_url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          this.customers = response.data?.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getAllAgency() {
      const api_url = process.env.VUE_APP_BASEURL + "/agency/getAll";
      axios
        .get(api_url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          this.agencies = response.data?.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    formatDate(date) {
      return format(date, "dd/MM/yyyy");
    },
    formatDateData(date) {
      return format(date, "yyyy-MM-dd");
    },
  },
};
</script>
<template>
  <div>
    <div class="form-group row">
      <label class="col-md-2 col-form-label">Müşteri</label>
      <div class="col-md-10">
        <multiselect
          v-model="values.customer"
          :options="customers"
          label="customer_name"
          @input="updateSelectedValues"
        ></multiselect>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-md-2 col-form-label">Acenta</label>
      <div class="col-md-10">
        <multiselect
          v-model="values.agency"
          :options="agencies"
          label="company_name"
          @input="updateSelectedValues"
        ></multiselect>
      </div>
    </div>
    <div class="form-group row">
      <label class="col-md-2 col-form-label">Rezervasyon Notu</label>
      <div class="col-md-10">
        <input
          v-model="values.note"
          type="text"
          class="form-control"
          id="note"
          @input="updateSelectedValues"
        />
      </div>
    </div>
  </div>
</template>
