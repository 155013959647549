<script>
import Multiselect from "vue-multiselect";
import axios from "axios";

export default {
  props: ["tourRoutes", "extras", "editTourProps", "reservations"],
  components: {
    Multiselect,
  },
  mounted() {
    this.tourReservation.extras = this.extras;
  },
  data() {
    return {
      minFirstDate: "",
      tourReservation: {
        extras: {
          first_date: "",
          last_date: "",
          total_day: 0,
          baby: "",
          children: "",
          adult: "",
        },
        routes: {
          order: "",
          date: "",
          city: "",
          tour_type: "",
          vehicle: "",
          driver: "",
          pay_type: "",
          buy_price: "",
          purchase_currency: "",
          sell_price: "",
          sale_currency: "",
          currency: "",
          sub_agency: false,
          sub_agency_id: "",
        },
      },
      pay_type: [
        { value: 1, label: "Müşteri Ödemeli" },
        { value: 2, label: "Acenta Ödemeli" },
      ],
      types: [
        { value: 1, label: "Transfer" },
        { value: 2, label: "Tur" },
        { value: 3, label: "Transfer ve Tur" },
        { value: 4, label: "Şehirler Arası Transfer" },
        { value: 5, label: "Şehirler Arası Tur ve Transfer" },
      ],
    };
  },
  created() {
    this.$root.$on("minFirstDateUpdated", (minFirstDate) => {
      console.log(minFirstDate);
      this.minFirstDate = minFirstDate;
    });
  },
  watch: {
    tourReservation: {
      handler: function () {
        this.updateExtras();
      },
      deep: true,
    },
    "tourReservation.extras.first_date": "setMinFirstDate",
  },
  methods: {
    setMinFirstDate() {
      const reservationFirstDates = this.reservations.map(
        (row) => new Date(row.first_date)
      );

      this.minFirstDate = this.$moment(new Date(Math.min(...reservationFirstDates))).format("YYYY-MM-DD");

    },
    updateExtras() {
      this.$emit("update:extras", {
        ...this.extras,
        first_date: new Date(this.tourReservation.extras.first_date),
        last_date: new Date(this.tourReservation.extras.last_date),
        total_day: this.tourReservation.extras.total_day,
        baby: this.tourReservation.extras.baby,
        children: this.tourReservation.extras.children,
        adult: this.tourReservation.extras.adult,
      });
    },
    getData(url, prop) {
      const api_url = process.env.VUE_APP_BASEURL + url;
      axios
        .get(api_url, {
          headers: {
            Authorization: `Bearer ${this.getToken()}`,
          },
        })
        .then((response) => {
          this[prop] = response.data?.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    addTourRoutesToReservation() {
      const newOrderNumber = this.tourRoutes.length + 1;
      this.tourReservation.routes.order = newOrderNumber;
      if (this.tourReservation.routes) {
        const newTourRoutesReservation = {
          order: newOrderNumber,
          date: this.tourReservation.routes.date,
          city: this.tourReservation.routes.city,
          tour_type: this.tourReservation.routes.tour_type,
          vehicle: this.tourReservation.routes.vehicle,
          driver: this.tourReservation.routes.driver,
          sub_agency: this.tourReservation.routes.sub_agency,
          sub_agency_id: this.tourReservation.routes.sub_agency_id,
          //
          payment_type: this.tourReservation.routes.pay_type,
          buy_price: this.tourReservation.routes.buy_price,
          buy_currency: this.tourReservation.routes.purchase_currency,
          sell_price: this.tourReservation.routes.sell_price,
          sell_currency: this.tourReservation.routes.sale_currency,
          //
          types: this.types,
          pay_types: this.pay_type,
        };
        this.tourRoutes.push(newTourRoutesReservation);
        this.resetTourReservationRoutes();
        this.$emit("update:selectedValues", this.tourRoutes);
      }
      this.updateTotalDays();
      if (this.tourReservation.extras) {
        const newTourRoutesExtras = {
          first_date: this.tourReservation.extras.first_date,
          last_date: this.tourReservation.extras.last_date,
          total_day: this.tourReservation.extras.total_day,
          baby: this.tourReservation.extras.baby,
          children: this.tourReservation.extras.children,
          adult: this.tourReservation.extras.adult,
        };
        this.extras.push(newTourRoutesExtras);
        this.$emit("update:extras", this.extras);
      }
    },
    resetTourReservationRoutes() {
      this.tourReservation.routes = {
        order: "",
        date: "",
        city: "",
        tour_type: "",
        vehicle: "",
        driver: "",
        pay_type: "",
        buy_price: "",
        purchase_currency: "",
        sell_price: "",
        sale_currency: "",
        currency: "",
        sub_agency: false,
        sub_agency_id: "",
      };
    },
    isFormValid() {
      if (this.tourReservation.routes.sub_agency) {
        return (
          this.tourReservation.routes.order &&
          this.tourReservation.routes.date &&
          this.tourReservation.routes.city &&
          this.tourReservation.routes.tour_type &&
          this.tourReservation.routes.sub_agency_id &&
          this.tourReservation.routes.pay_type &&
          this.tourReservation.routes.buy_price &&
          this.tourReservation.routes.purchase_currency &&
          this.tourReservation.routes.sell_price &&
          this.tourReservation.routes.sale_currency
        );
      } else {
        return (
          this.tourReservation.routes.order &&
          this.tourReservation.routes.date &&
          this.tourReservation.routes.city &&
          this.tourReservation.routes.tour_type &&
          this.tourReservation.routes.vehicle &&
          this.tourReservation.routes.driver &&
          this.tourReservation.routes.pay_type &&
          this.tourReservation.routes.buy_price &&
          this.tourReservation.routes.purchase_currency &&
          this.tourReservation.routes.sell_price &&
          this.tourReservation.routes.sale_currency
        );
      }
    },
    isGeneralFormValid() {
      return (
        this.tourReservation.routes.order &&
        this.tourReservation.routes.date &&
        this.tourReservation.routes.city &&
        this.tourReservation.routes.tour_type &&
        this.tourReservation.routes.vehicle &&
        this.tourReservation.routes.driver &&
        this.tourReservation.routes.pay_type &&
        this.tourReservation.routes.buy_price &&
        this.tourReservation.routes.purchase_currency &&
        this.tourReservation.routes.sell_price &&
        this.tourReservation.routes.sale_currency
      );
    },
    resetErrorMessages() {
      this.$refs.OrderErrorMessage.style.display = "none";
      this.$refs.DateErrorMessage.style.display = "none";
      this.$refs.CityErrorMessage.style.display = "none";
      this.$refs.TypeErrorMessage.style.display = "none";
      if (!this.tourReservation.routes.sub_agency) {
        this.$refs.PlateErrorMessage.style.display = "none";
        this.$refs.DriverErrorMessage.style.display = "none";
      } else {
        this.$refs.SubAgencyErrorMessage.style.display = "none";
      }
      this.$refs.PayTypeErrorMessage.style.display = "none";
      this.$refs.PurchasePriceErrorMessage.style.display = "none";
      this.$refs.PurchaseCurrencyErrorMessage.style.display = "none";
      this.$refs.SalePriceErrorMessage.style.display = "none";
      this.$refs.SaleCurrencyErrorMessage.style.display = "none";

      if (!this.tourReservation.routes.order) {
        this.$refs.OrderErrorMessage.style.display = "flex";
      }
      if (!this.tourReservation.routes.date) {
        this.$refs.DateErrorMessage.style.display = "flex";
      }
      if (!this.tourReservation.routes.city) {
        this.$refs.CityErrorMessage.style.display = "flex";
      }
      if (!this.tourReservation.routes.tour_type) {
        this.$refs.TypeErrorMessage.style.display = "flex";
      }
      if (!this.tourReservation.routes.sub_agency) {
        if (!this.tourReservation.routes.vehicle) {
          this.$refs.PlateErrorMessage.style.display = "flex";
        }
        if (!this.tourReservation.routes.driver) {
          this.$refs.DriverErrorMessage.style.display = "flex";
        }
      } else {
        if (
          this.tourReservation.routes.sub_agency &&
          !this.tourReservation.routes.sub_agency_id
        ) {
          this.$refs.SubAgencyErrorMessage.style.display = "flex";
        }
      }
      if (!this.tourReservation.routes.pay_type) {
        this.$refs.PayTypeErrorMessage.style.display = "flex";
      }
      if (!this.tourReservation.routes.buy_price) {
        this.$refs.PurchasePriceErrorMessage.style.display = "flex";
      }
      if (!this.tourReservation.routes.purchase_currency) {
        this.$refs.PurchaseCurrencyErrorMessage.style.display = "flex";
      }
      if (!this.tourReservation.routes.sell_price) {
        this.$refs.SalePriceErrorMessage.style.display = "flex";
      }
      if (!this.tourReservation.routes.sale_currency) {
        this.$refs.SaleCurrencyErrorMessage.style.display = "flex";
      }
    },

    submitForm() {
      this.resetErrorMessages();

      if (!this.tourReservation.routes.sub_agency) {
        if (!this.isGeneralFormValid()) {
          alert("Lütfen tüm genel alanları doldurun.");
          return;
        }
      } else {
        if (!this.isFormValid()) {
          alert("Lütfen tüm alanları doldurun.");
          return;
        }
      }
      this.addTourRoutesToReservation();
    },
    updateTotalDays() {
      const startDate = new Date(this.tourReservation.extras.first_date);
      const endDate = new Date(this.tourReservation.extras.last_date);

      const timeDifference = endDate.getTime() - startDate.getTime();
      const dayDifference = Math.max(
        timeDifference / (1000 * 3600 * 24) + 1,
        1
      );

      if (this.tourReservation.routes) {
        this.tourReservation.routes.order = this.tourRoutes.length + 1;
      }
      this.tourReservation.extras.total_day = dayDifference;
    },
  },
};
</script>
<template>
  <div>
    <div class="row">
      <div class="col-12 col-md-12 mb-2">
        <h5>Tur Detayları</h5>
      </div>
      <div class="col-12 col-md-6 mb-3">
        <label>Giriş Tarihi</label>
        <input
          class="form-control"
          type="date"
          v-model="tourReservation.extras.first_date"
          :first-day-of-week="1"
          :min="minFirstDate"
          @change="updateTotalDays"
          lang="tr"
          ref="datePicker"
        />
      </div>
      <div class="col-12 col-md-6 mb-3">
        <label>Çıkış Tarihi</label>
         <input
          class="form-control"
          type="date"
          v-model="tourReservation.extras.last_date"
          :first-day-of-week="1"
          @change="updateTotalDays"
          :min="tourReservation.extras.first_date"
          lang="tr"
        />
      </div>
      <div class="col-12 col-md-3">
        <label>Toplam Gün</label>
        <input
          type="number"
          v-model="tourReservation.extras.total_day"
          class="form-control"
        />
      </div>
      <div class="col-12 col-md-3">
        <label>Bebek</label>
        <input
          type="number"
          v-model="tourReservation.extras.baby"
          class="form-control"
        />
      </div>
      <div class="col-12 col-md-3">
        <label>Çocuk</label>
        <input
          type="number"
          v-model="tourReservation.extras.children"
          class="form-control"
        />
      </div>
      <div class="col-12 col-md-3">
        <label>Yetişkin</label>
        <input
          type="number"
          v-model="tourReservation.extras.adult"
          class="form-control"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-12">
        <hr />
      </div>
      <div class="col-12 col-md-12 mb-2">
        <h5>Rota Bilgileri</h5>
      </div>
      <div class="col-12 col-md-12 mb-2">
        <div class="form-check mb-3">
          <input
            class="form-check-input"
            v-model="tourReservation.routes.sub_agency"
            type="checkbox"
            id="subAgencyCheck"
            @change="handleSubAgencyChange"
          />
          <label class="form-check-label" for="subAgencyCheck">Acenta</label>
        </div>
      </div>
      <div class="col-12 col-md-1">
        <div class="form-group mb-3">
          <label for="order">Sıra</label>
          <input
            type="text"
            v-model="tourReservation.routes.order"
            class="form-control"
            id="order"
            ref="order"
          />
          <label
            class="error-input"
            ref="OrderErrorMessage"
            style="display: none"
          >
            Bu alan boş bırakılamaz.
          </label>
        </div>
      </div>
      <div class="col-12 col-md-2">
        <div class="form-group mb-3">
          <label for="date">Tarih</label>
          <input
            type="date"
            v-model="tourReservation.routes.date"
            class="form-control"
            id="date"
            ref="date"
            :min="tourReservation.extras.first_date"
            :max="tourReservation.extras.last_date"
          />
          <label
            class="error-input"
            ref="DateErrorMessage"
            style="display: none"
          >
            Bu alan boş bırakılamaz.
          </label>
        </div>
      </div>
      <div class="col-12 col-md-2">
        <div class="form-group mb-3">
          <label for="city">Şehir</label>
          <multiselect
            v-model="tourReservation.routes.city"
            :options="this.editTourProps.cities"
            label="city"
          >
          </multiselect>
          <label
            class="error-input"
            ref="CityErrorMessage"
            style="display: none"
          >
            Bu alan boş bırakılamaz.
          </label>
        </div>
      </div>
      <div class="col-12 col-md-2">
        <div class="form-group mb-3">
          <label for="type">Tur Tipi</label>
          <multiselect
            v-model="tourReservation.routes.tour_type"
            :options="types"
            label="label"
            ref="type"
          >
          </multiselect>
          <label
            class="error-input"
            ref="TypeErrorMessage"
            style="display: none"
          >
            Bu alan boş bırakılamaz.
          </label>
        </div>
      </div>
      <div class="col-12 col-md-2" v-if="!tourReservation.routes.sub_agency">
        <div class="form-group mb-3">
          <label for="plate">Plaka</label>
          <multiselect
            v-model="tourReservation.routes.vehicle"
            :options="this.editTourProps.vehicles"
            label="plate"
            ref="plate"
          >
          </multiselect>
          <label
            class="error-input"
            ref="PlateErrorMessage"
            style="display: none"
          >
            Bu alan boş bırakılamaz.
          </label>
        </div>
      </div>
      <div class="col-12 col-md-3" v-if="!tourReservation.routes.sub_agency">
        <div class="form-group mb-3">
          <label for="driver">Araç Söförü</label>
          <multiselect
            v-model="tourReservation.routes.driver"
            :options="this.editTourProps.drivers"
            label="name"
            ref="driver"
          >
          </multiselect>
          <label
            class="error-input"
            ref="DriverErrorMessage"
            style="display: none"
          >
            Bu alan boş bırakılamaz.
          </label>
        </div>
      </div>
      <div class="col-12 col-md-2" v-if="tourReservation.routes.sub_agency">
        <div class="form-group mb-3">
          <label for="sub_agency_select">Acenta Seç</label>
          <multiselect
            v-model="tourReservation.routes.sub_agency_id"
            :options="this.editTourProps.agencies"
            @input="handleSubAgencyChange"
            label="company_name"
            ref="sub_agency_select"
          >
          </multiselect>
          <!-- Acenta seçimi için hata kontrolü ekleyebilirsiniz -->
          <label
            class="error-input"
            ref="SubAgencyErrorMessage"
            style="display: none"
          >
            Bu alan boş bırakılamaz.
          </label>
        </div>
      </div>
      <div class="col-12 col-md-3">
        <div class="form-group mb-3">
          <label for="pay_type">Ödeme Tipi</label>
          <multiselect
            v-model="tourReservation.routes.pay_type"
            :options="pay_type"
            label="label"
            ref="pay_type"
          >
          </multiselect>
          <label
            class="error-input"
            ref="PayTypeErrorMessage"
            style="display: none"
          >
            Bu alan boş bırakılamaz.
          </label>
        </div>
      </div>
      <div class="col-12 col-md-2">
        <div class="form-group mb-3">
          <label for="buy_price">Alış Fiyat</label>
          <input
            type="text"
            v-model="tourReservation.routes.buy_price"
            class="form-control"
            id="buy_price"
            ref="buy_price"
          />
          <label
            class="error-input"
            ref="PurchasePriceErrorMessage"
            style="display: none"
          >
            Bu alan boş bırakılamaz.
          </label>
        </div>
      </div>
      <div class="col-12 col-md-2">
        <div class="form-group mb-3">
          <label for="purchase_currency">Alış Kur</label>
          <multiselect
            v-model="tourReservation.routes.purchase_currency"
            :options="this.editTourProps.currencies"
            label="name"
            ref="purchase_currency"
          >
          </multiselect>
          <label
            class="error-input"
            ref="PurchaseCurrencyErrorMessage"
            style="display: none"
          >
            Bu alan boş bırakılamaz.
          </label>
        </div>
      </div>

      <div class="col-12 col-md-3">
        <div class="form-group mb-3">
          <label for="sell_price">Satış Fiyat</label>
          <input
            type="text"
            v-model="tourReservation.routes.sell_price"
            class="form-control"
            id="sell_price"
            ref="sell_price"
          />
          <label
            class="error-input"
            ref="SalePriceErrorMessage"
            style="display: none"
          >
            Bu alan boş bırakılamaz.
          </label>
        </div>
      </div>
      <div class="col-12 col-md-2">
        <div class="form-group mb-3">
          <label for="sale_currency">Satış Kur</label>
          <multiselect
            v-model="tourReservation.routes.sale_currency"
            :options="this.editTourProps.currencies"
            label="name"
            ref="sale_currency"
          >
          </multiselect>
          <label
            class="error-input"
            ref="SaleCurrencyErrorMessage"
            style="display: none"
          >
            Bu alan boş bırakılamaz.
          </label>
        </div>
      </div>
      <div class="col-12 col-md-12">
        <button class="float-right btn btn-success w-100" @click="submitForm">
          Rota Ekle
        </button>
      </div>
    </div>
  </div>
</template>
<style>
/* Hata alanlarını vurgulamak için kırmızı sınır ekleyin */
.error-input {
  color: red;
}
</style>
