FlyModal.vue

<script>
import Multiselect from "vue-multiselect";
import { flyPacket } from "@/components/enums.js";

export default {
  props: ["flyRoutes", "editProps"],
  components: {
    Multiselect,
  },
  data() {
    return {
      flyPacket,
      showModal: false,
      editedRouteIndex: null,
      editedProps: [],
      editedRoute: {
        airline_name: "",
        flight_number: "",
        flight_pnr: "",
        flight_from: "",
        flight_to: "",
        flight_from_date: "",
        flight_to_date: "",
        flight_ticket_number: "",
        flight_ticket_class: "",
        flight_baggage: "",
        reservation_number: "",
        packet: "",
        preferences: "",
        buy_price: "",
        sell_price: "",
        buy_currency: "",
        sell_currency: "",
        customer: "",
      },
    };
  },
  mounted() {
    this.editedRoute = this.flyRoutes;
    console.log(this.editedRoute);
    this.$on("open-edit-modal", (flyRoutes, currencies, customers) => {
      this.showModal = true;
      this.editedRoute = flyRoutes;
      this.editedRoute.customers = customers;
      this.editedRoute.currencies = currencies;
    });
  },
  methods: {
    formatDate(date) {
      const options = { year: "numeric", month: "long", day: "numeric" };
      return new Date(date).toLocaleDateString(undefined, options);
    },
    openModal(index, editedRouteData) {
      this.editedRouteIndex = index;
      this.editedRoute = { ...editedRouteData };
      this.editedProps = this.editProps;
      this.showModal = true;
    },
    closeAndResetModal() {
      this.editedRouteIndex = null;
      this.showModal = false;
      this.editedRoute = {
        airline_name: "",
        flight_number: "",
        flight_pnr: "",
        flight_from: "",
        flight_to: "",
        flight_from_date: "",
        flight_to_date: "",
        flight_ticket_number: "",
        flight_ticket_class: "",
        flight_baggage: "",
        reservation_number: "",
        packet: "",
        preferences: "",
        buy_price: "",
        sell_price: "",
        buy_currency: "",
        sell_currency: "",
        customer: "",
        currency: "",
      };
    },
    updateRoute() {
      this.$emit("update-route", this.editedRouteIndex, this.editedRoute);
      this.closeAndResetModal();
    },
  },
};
</script>
<template>
  <b-modal
    v-model="showModal"
    title="Rota Düzenleme"
    id="modal-xl"
    size="xl"
    hide-footer
  >
    <div>
      <div class="row">
        <div class="col-12 col-md-12 mb-2">
          <h5>Uçuş Bilgileri</h5>
        </div>
        <div class="col-12 col-md-3">
          <div class="form-group mb-3">
            <label for="airline_name">Havayolu Şirketi</label>
            <input
              v-model="editedRoute.airline_name"
              type="text"
              class="form-control"
              id="airline_name"
            />
          </div>
        </div>
        <div class="col-12 col-md-3">
          <div class="form-group mb-3">
            <label for="flight_number">Uçuş Kodu</label>
            <input
              v-model="editedRoute.flight_number"
              type="text"
              class="form-control"
              id="flight_number"
            />
          </div>
        </div>
        <div class="col-12 col-md-3">
          <div class="form-group mb-3">
            <label for="reservation_number">Rezervasyon Kodu</label>
            <input
              v-model="editedRoute.reservation_number"
              type="text"
              class="form-control"
              id="reservation_number"
            />
          </div>
        </div>
        <div class="col-12 col-md-3">
          <div class="form-group mb-3">
            <label for="flight_pnr">PNR Kodu</label>
            <input
              v-model="editedRoute.flight_pnr"
              type="text"
              class="form-control"
              id="flight_pnr"
            />
          </div>
        </div>
        <div class="col-12 col-md-3">
          <div class="form-group mb-3">
            <label for="flight_from">Kalkış</label>
            <multiselect
              v-model="editedRoute.flight_from"
              :options="editProps.airports"
              label="name"
            >
            </multiselect>
          </div>
        </div>
        <div class="col-12 col-md-3">
          <div class="form-group mb-3">
            <label for="flight_to">Varış</label>
            <multiselect
              v-model="editedRoute.flight_to"
              :options="editProps.airports"
              label="name"
            >
            </multiselect>
          </div>
        </div>
        <div class="col-12 col-md-3">
          <div class="form-group mb-3">
            <label for="flight_from_date">Kalkış Zamanı</label>
            <input
              type="datetime-local"
              v-model="editedRoute.flight_from_date"
              :first-day-of-week="1"
              lang="tr"
              class="form-control"
            />
          </div>
        </div>
        <div class="col-12 col-md-3">
          <div class="form-group mb-3">
            <label for="flight_to_date">Varış Zamanı</label>
            <input
              type="datetime-local"
              v-model="editedRoute.flight_to_date"
              :first-day-of-week="1"
              lang="tr"
              class="form-control"
            />
          </div>
        </div>
        <div class="col-12 col-md-3">
          <div class="form-group mb-3">
            <label for="flight_ticket_number">Bilet Numarası</label>
            <input
              v-model="editedRoute.flight_ticket_number"
              type="text"
              class="form-control"
              id="flight_ticket_number"
            />
          </div>
        </div>
        <div class="col-12 col-md-3">
          <div class="form-group mb-3">
            <label for="flight_ticket_class">Bilet Sınıfı</label>
            <multiselect
              v-model="editedRoute.flight_ticket_class"
              :options="flyPacket"
              label="text"
            >
            </multiselect>
          </div>
        </div>
        <div class="col-12 col-md-3">
          <div class="form-group mb-3">
            <label for="packet">Paket</label>
            <input
              v-model="editedRoute.packet"
              type="text"
              class="form-control"
              id="packet"
            />
          </div>
        </div>
        <div class="col-12 col-md-3">
          <div class="form-group mb-3">
            <label for="flight_baggage">Bagaj Hakkı (kg)</label>
            <input
              v-model="editedRoute.flight_baggage"
              type="text"
              class="form-control"
              id="flight_baggage"
            />
          </div>
        </div>
        <div class="col-12 col-md-3">
          <div class="form-group mb-3">
            <label for="buy_price">Alış Fiyatı</label>
            <input
              v-model="editedRoute.buy_price"
              type="double"
              class="form-control"
              id="buy_price"
            />
          </div>
        </div>
        <div class="col-12 col-md-3">
          <div class="form-group mb-3">
            <label for="buy_currency">Alış Kur</label>
            <multiselect
              v-model="editedRoute.buy_currency"
              :options="this.editProps.currencies"
              label="name"
            >
            </multiselect>
          </div>
        </div>
        <div class="col-12 col-md-3">
          <div class="form-group mb-3">
            <label for="sell_price">Satış Fiyatı</label>
            <input
              v-model="editedRoute.sell_price"
              type="double"
              class="form-control"
              id="sell_price"
            />
          </div>
        </div>
        <div class="col-12 col-md-3">
          <div class="form-group mb-3">
            <label for="kur">Satış Kur</label>
            <multiselect
              v-model="editedRoute.sell_currency"
              :options="this.editProps.currencies"
              label="name"
              :hover="false"
            ></multiselect>
          </div>
        </div>
        <div class="col-12 col-md-3">
          <div class="form-group mb-3">
            <label for="boards">Yolcu</label>
            <multiselect
              v-model="editedRoute.customer"
              :options="this.editProps.customers"
              label="customer_name"
            ></multiselect>
          </div>
        </div>
        <div class="col-12 col-md-9">
          <div class="form-group mb-3">
            <label for="preferences">Tercihler</label> <br />
            <textarea
              v-model="editedRoute.preferences"
              class="form-control"
              id="preferences"
            ></textarea>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 text-right">
          <button @click="updateRoute" class="btn btn-success">
            Güncelle
            <i class="bi bi-check ml-2"></i>
          </button>
          <button @click="closeAndResetModal" class="btn btn-danger ml-2">
            İptal
            <i class="bi bi-x ml-2"></i>
          </button>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<style>
/* Hata alanlarını vurgulamak için kırmızı sınır ekleyin */
.error-input {
  color: red;
}
</style>
