FlyTable.vue

<script>
import FlyModal from "./FlyModal.vue";
import { Icon } from "@iconify/vue2";

export default {
  components: {
    FlyModal,
    Icon,
  },
  props: ["flyRoutes", "editProps"],
  computed: {
    tableFields() {
      return [
        { key: "airline_name", label: "Havayolu Şirketi" },
        {
          key: "flight_number",
          label: "Uçuş Kodu",
        },
        {
          key: "flight_pnr",
          label: "PNR Kodu",
        },
        {
          key: "flight_from",
          label: "Kalkış",
        },
        {
          key: "flight_to",
          label: "Varış",
        },
        {
          key: "flight_ticket_number",
          label: "Bilet Numarası",
        },
        {
          key: "flight_ticket_class.text",
          label: "Bilet Sınıfı",
        },
        {
          key: "flight_baggage",
          label: "Bagaj Hakkı(kg)",
        },
        {
          key: "customer.customer_name",
          label: "Yolcu",
        },
        {
          key: "buy_currency",
          label: "Alış",
          formatter: (key, value, item) =>
            item.buy_price + " " + item.buy_currency.symbol,
        },
        {
          key: "sell_currency",
          label: "Satış",
          formatter: (key, value, item) =>
            item.sell_price + " " + item.sell_currency.symbol,
        },
        { key: "actions", label: "İşlemler" },
      ];
    },
  },
  mounted() {
    console.log("tabele", this.flyRoutes);
  },
  methods: {
    formatDate(date) {
      const options = { year: "numeric", month: "long", day: "numeric" };
      return new Date(date).toLocaleDateString(undefined, options);
    },
    openEditModal(index) {
      this.$refs.editModal.openModal(index, this.flyRoutes[index]);
      this.$emit("open-edit-modal", this.flyRoutes, this.editProps);
    },
    updateRouteInTable(index, updatedRouteData) {
      this.$set(this.flyRoutes, index, updatedRouteData);
    },
    deleteRoute(index) {
      console.log(index);

      if (index !== -1) {
        this.flyRoutes.splice(index, 1);
      }
    },
  },
};
</script>

<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Rota Listesi</h4>
          <b-table
            :items="this.flyRoutes"
            :fields="tableFields"
            hover
            class="mt-3"
          >
            <template v-slot:cell(flight_from)="data">
              {{ data.item.flight_from.label }}

              {{ data.item.flight_from.name }} <br />
              {{ data.item.flight_from_date }}
            </template>
            <template v-slot:cell(flight_to)="data">
              {{ data.item.flight_to.label }}
                
              {{ data.item.flight_to.name }} <br />
              {{ data.item.flight_to_date }}
            </template>
            <!-- <template v-slot:cell(date)="data">
              {{ formatDate(data.item.date) }}
            </template>
            <template v-slot:cell(driver)="data">
              {{ 
                data.item.sub_agency ? data.item.sub_agency_id.company_name : data.item.driver.name
              }}
            </template>
            <template v-slot:cell(plate)="data">
              {{ 
                data.item.sub_agency ? "Acenta: " : data.item.vehicle.plate
              }}
            </template> -->
            <template v-slot:cell(actions)="data">
                <div class="d-flex justify-content-between align-items-center">
                  <button
                    @click="openEditModal(data.index)"
                    class="btn btn-sm btn-secondary"
                  >
                    <Icon icon="lucide:file-edit" />
                  </button>
                  <button
                    @click="deleteRoute(data.index)"
                    class="btn btn-sm btn-danger ml-2"
                  >
                    <Icon icon="lucide:trash-2" />
                  </button>
                </div>
              </template>
          </b-table>
          <FlyModal
            ref="editModal"
            :flyRoutes="flyRoutes"
            :editProps="editProps"
            @update-route="updateRouteInTable"
          />
          <div class="row">
            <div class="col">
              <div
                class="dataTables_paginate paging_simple_numbers float-right"
              >
                <ul class="pagination pagination-rounded mb-0">
                  <!-- pagination -->
                  <!-- <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" @change="onPageChange"></b-pagination> -->
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
